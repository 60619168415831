<template>
  <div class="detail-one list-scroll" v-loading="loadingDetail">
    <h1 class="detail-one-title">{{ detail.name }}</h1>
    <div class="detail-one-divider"></div>
    <!-- <p class="detail-one-time">发布时间：{{ detail.time }}</p> -->
    <div class="detail-one-content" v-html="detail.body"></div>
  </div>
</template>

<script>
import { getModuleBaseMes } from "@/api/base.js";
import { Loading } from 'element-ui';

export default {
  name: "DetailOne",
  data() {
    return {
      loadingDetail: false,
      searchObj: {
        moduleCode: "",
        typeId: 0,
        cId: 0,
      },
      detail: {},
    };
  },
  created() {
    const { query } = this.$route;
    this.searchObj.moduleCode = query.module_code;
    this.searchObj.cId = query.cid;
    this.getModuleBaseMes();
  },
  mounted() {},
  methods: {
    getModuleBaseMes() {
      // this.loadingDetail = true;
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = {
        module_code: this.searchObj.moduleCode,
        id: this.searchObj.cId,
      };
      getModuleBaseMes(params)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.detail = data;
          this.$nextTick(()=>{
            // this.loadingDetail = false;
            loadingInstance.close();
          })
          this.$emit("clickItem", data.name);
        })
        .catch((err) => {
          console.log(err);
          // this.loadingDetail = false;
          loadingInstance.close();
        })
        .finally(() => {
          // this.loadingDetail = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .detail-one {
    max-height: calc(100% - 46px) !important;

    .detail-one-title {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .detail-one {
    max-height: 100% !important;

    .detail-one-title {
      font-size: 18px;
    }
  }
}
.detail-one {
  overflow-y: auto;

  .detail-one-title {
    text-align: center;
    margin-top: 20px;
  }

  .detail-one-divider {
    height: 1px;
    background: #343434;
    margin: 10px 0;
  }

  .detail-one-date {
  }

  .detail-one-content {
    /deep/ img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>